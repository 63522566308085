import React from "react";
import { Button } from "../Button/Button";
import "./HeroSection.css";
import "../../App.css";

function HeroSection() {
  return (
    <div className="HeroSection">
      <div className="hero-container">
        <video src="/videos/video-2.mp4" autoPlay loop muted></video>
        <h1>ADVANTURE AWAITS</h1>
        <p>What are you waiting for?</p>
        <div className="hero-btns">
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
          >
            GET STARTED
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
          >
            WATCH TRAILER <i className="far fa-play-circle" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
